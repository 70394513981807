// ----------------------------------------------------------------------------
// Variables
// ----------------------------------------------------------------------------

// Spacing
$spacer: 1rem !default;

// Font
$font-size-xxl: 1.75rem !default;
$font-size-xl: 1.5rem !default;
$font-size-lg: 1.25rem !default;
$font-size-base: 1rem !default;
$font-size-sm: 0.875rem !default;

$font-family-base: 'Raleway', sans-serif !default;

$font-weight-light: 300 !default;
$font-weight-base: 400 !default;
$font-weight-medium: 500 !default;
$font-weight-semibold: 600 !default;
$font-weight-bold: 700 !default;
$font-weight-black: 900 !default;

// Container
$container-width: 600px !default;

// Breakpoints
$bp-xs: 480px !default;
$bp-sm: 576px !default;
$bp-md: 768px !default;
$bp-lg: 1024px !default;
$bp-lg: 1280px !default;

// Colors
$color-primary: #031857 !default;
$color-secondary: #c40f39 !default;
$color-tertiary: #6aacff !default;

$color-gray-light: #d1d1d1 !default;

$color-success: #009d62 !default;
$color-danger: #c40f39 !default;

$color-type-base: #636363 !default;
$color-type-light: #ababab !default;

$color-border-base: #d1d1d1 !default;
$color-border-light: #e0e0e0 !default;

// Border
$border-radius-base: 2px !default;

// Animation

$transition-base: all 300ms;
