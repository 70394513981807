
          @import "@/assets/scss/main.scss";
        









































































































































































































































































































































































































































































































































































































































































































































































// General
.form {
  margin: auto;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.form-label {
  font-size: $font-size-sm;
  font-weight: $font-weight-bold;

  @include rem(margin-bottom, 10px);

  &.is-disabled {
    pointer-events: none;
  }
}

.form-select,
/deep/ .date-dropdown__select {
  padding-right: $spacer * 2;
  background: white url('../assets/img/chevron.png') no-repeat;

  @include rem(background-size, 13px);

  background-position: right $spacer/2 center;
  font-weight: $font-weight-base;
  color: black;
  cursor: pointer;
}

.form-input,
/deep/ .date-dropdown__select {
  appearance: none;
  width: 100%;

  @include rem(min-height, 45px);

  padding: 0 $spacer/2;
  margin-bottom: $spacer;
  border: 0;
  border-radius: $border-radius-base;
  font-size: $font-size-sm;
  font-weight: $font-weight-base;

  .form--light & {
    border: 1px solid $color-border-base;
  }

  &.is-invalid {
    border: 2px solid $color-danger;
    border-bottom: 0;

    &::placeholder {
      color: $color-danger;
    }

    .form--light & {
      border-bottom: 1px solid $color-border-base;
    }
  }

  &.is-success {
    border: 2px solid $color-success;
    border-bottom: 0;

    .form--light & {
      border-bottom: 1px solid $color-border-base;
    }
  }

  &.is-disabled {
    background-color: darken($color-gray-light, 5%);
    pointer-events: none;
  }

  @media (min-width: $bp-xs) {
    padding: 0 $spacer/2;
  }
}

.form-date-disclaimer {
  margin: -$spacer 0 $spacer;
  font-size: $font-size-sm;
}

// Password feedback
.form-password-feedback {
  padding: $spacer;

  @include rem(margin, -18px 0 16px);

  border: 2px solid $color-danger;
  border-top: 0;

  div {
    display: flex;
    font-size: $font-size-sm;
  }

  i {
    margin: $spacer/10 $spacer/3 0 0;

    @include rem(font-size, 16px);
  }

  span,
  i {
    &.is-danger {
      color: $color-danger;
    }

    &.is-success {
      color: $color-success;
    }
  }

  &.is-success {
    border-color: $color-success;
  }

  .form:not(.form--light) & span {
    color: white;
  }
}

// Manual entry
.form-manual-entry {
  position: relative;
  align-self: flex-end;
  margin: -$spacer 0 $spacer;
  font-size: $font-size-sm;
  z-index: 1;
}

// Submit
.form-submit {
  margin-top: $spacer;

  > .btn {
    width: 100%;
    padding: 0;

    @include rem(max-width, 230px);

    @media (min-width: $bp-sm) {
      margin-right: $spacer*2;
    }
  }
}

// Terms
.form-terms {
  font-size: $font-size-sm;
  margin-bottom: $spacer;

  @media (min-width: $bp-sm) {
    margin-bottom: 0;
  }
}

// Link Alt
.link-alt {
  margin-top: $spacer;

  @media (min-width: $bp-sm) {
    margin-top: 0;
  }
}

// Separator
hr {
  margin: $spacer*2 0 $spacer*3;
  transition: $transition-base;
}

// Modifiers
.form-submit--multiple-links {
  justify-content: space-between;

  > a {
    margin-left: 0;
  }
}
