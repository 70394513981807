
          @import "@/assets/scss/main.scss";
        



































// General
* {
  box-sizing: border-box;
}

body {
  margin: $spacer/2;
  color: $color-type-base;
  font-family: $font-family-base;
  font-size: $font-size-base;
  font-weight: $font-weight-base;
  line-height: 1.5;

  @media (min-width: $bp-xs) {
    margin: $spacer;
  }
}

h2,
h3,
h4 {
  margin: 0 0 $spacer;
  font-weight: $font-weight-black;
  text-transform: uppercase;
  color: $color-primary;
  line-height: 1;
}

h2 {
  font-size: $font-size-base;

  @media (min-width: $bp-sm) {
    font-size: $font-size-xl;
  }

  @media (min-width: $bp-md) {
    font-size: $font-size-xxl;
  }
}

h3 {
  font-size: $font-size-lg;
}

h4 {
  font-size: $font-size-base;
}

p {
  margin: 0 0 $spacer;
  font-size: $font-size-sm;
  font-weight: $font-weight-light;
  line-height: 2;
}

a {
  color: $color-type-base;
}

// Link Alt
.link-alt {
  display: inline-block;
  font-size: $font-size-sm;
  font-weight: $font-weight-semibold;
  color: $color-tertiary;
  transition: $transition-base;

  &:hover {
    color: lighten($color-tertiary, 10%);
  }
}

// Hide recaptcha badge
.grecaptcha-badge {
  visibility: collapse;
}

// Loading spin
.loading-spin-container {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loading-spin {
  color: white;
  animation: rotation 1.5s linear infinite;
}

// Button
.btn {
  position: relative;
  display: inline-block;

  @include rem(height, 45px);

  padding: 0 $spacer*3;
  background-color: $color-secondary;
  border: 0;
  border-radius: $border-radius-base;
  font-weight: $font-weight-black;
  color: white;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  transition: $transition-base;
  cursor: pointer;

  &::before {
    content: '';
    display: block;
    position: absolute;

    @include rem(top, 2px);
    @include rem(right, 2px);
    @include rem(bottom, 2px);
    @include rem(left, 2px);

    background-color: white;
    transition: $transition-base;
    transform: scale(0, 0);
    opacity: 0;
  }

  &:hover {
    box-shadow: inset 0 0 0 2px $color-secondary;

    &::before {
      transform: scale(1, 1);
      opacity: 1;
    }
  }

  &.is-loading {
    opacity: 0.5;
    pointer-events: none;
  }
}

.btn__label {
  position: relative;
  display: inline-block;
  z-index: 3;

  @include rem(line-height, 45px);

  color: white;
  transition: $transition-base;
  opacity: 1;

  .is-loading & {
    opacity: 0;
  }

  .btn:hover & {
    color: $color-secondary;
  }
}

// Separator
hr {
  margin: 0;
  border: 0;
  border-bottom: 2px solid $color-border-light;
}

// Unauthorized
.unauthorized {
  color: black;
}

// Helpers
.hide-mobile {
  @media (max-width: $bp-sm - 1) {
    display: none;
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-360deg);
  }
}
