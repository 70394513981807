
          @import "@/assets/scss/main.scss";
        




































// General
.alert {
  padding: $spacer;

  @include rem(margin, -18px 0 16px);

  font-size: $font-size-sm;
  font-weight: $font-weight-medium;
  border: 2px solid $color-danger;
  border-top: 0;
  border-radius: $border-radius-base;
  color: white;

  /deep/ a {
    color: $color-danger;
  }
}

// Modifiers
.alert--light {
  color: $color-danger;
}

.alert--single {
  padding-top: $spacer;
  margin-top: $spacer;
  border-top: 2px solid $color-danger;
}

.alert--overflow {
  @include rem(margin-top, -38px);

  @media (max-width: $bp-sm - 1) {
    padding-top: $spacer*2;
  }
}
