
          @import "@/assets/scss/main.scss";
        


































































































































































































































































// General
.grecaptcha-disclaimer {
  margin-top: $spacer*2;
  font-size: $font-size-sm;
}
