
          @import "@/assets/scss/main.scss";
        











































// Separator
.prompt__separator {
  position: relative;

  h3 {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;

    @include rem(width, 60px);
    @include rem(height, 60px);

    margin: 0;
    background-color: white;
    border: 2px solid $color-border-light;
    border-radius: 50%;
    transform: translate(-50%, -50%);
  }

  hr {
    margin: $spacer*4 0;
  }
}
