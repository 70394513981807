
          @import "@/assets/scss/main.scss";
        














































// General
.full {
  margin: auto;
  max-width: $container-width;

  h2 {
    margin-bottom: $spacer/2;
  }
}

// Description
.full__description {
  margin-bottom: $spacer*2;
  font-size: $font-size-sm;
  font-weight: $font-weight-medium;
}

// Logo
.full__logo {
  display: block;

  @include rem(width, 160px);

  margin: 0 auto $spacer*3;
  animation-delay: 0.2s;
}
