
          @import "@/assets/scss/main.scss";
        


























































































































































































































// General
.date-dropdown {
  display: flex;

  @include rem(max-width, 400px);
}

// Inputs
.date-dropdown__select {
  margin-right: $spacer/2;

  &:last-child {
    margin-right: 0;
  }

  @media (min-width: $bp-xs) {
    margin-right: $spacer/1.5;
  }
}
