
          @import "@/assets/scss/main.scss";
        















































// Customize the label (the container)
.checkbox {
  position: relative;
  padding-left: $spacer*2;
  margin: 0 auto $spacer 0;
  $font-weight: $font-weight-bold;

  font-size: $font-size-sm;
  cursor: pointer;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
}

// Custom checkbox styles
.checkbox__checkmark {
  position: absolute;
  top: 0;
  left: 0;

  @include rem(width, 20px);
  @include rem(height, 20px);

  background-color: white;
  border: 1px solid $color-border-base;
  border-radius: $border-radius-base;
  transition: $transition-base;
}

.checkbox:hover input ~ .checkbox__checkmark {
  background-color: $color-tertiary;
  border-color: $color-tertiary;
  transform: scale(1.3, 1.3);
}

.checkbox input:checked ~ .checkbox__checkmark {
  background-color: $color-secondary;
  border-color: $color-secondary;
}

.checkbox__checkmark::after {
  content: "";
  position: absolute;
}

.checkbox .checkbox__checkmark::after {
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);

  @include rem(top, 1px);
  @include rem(left, 5px);
  @include rem(width, 4px);
  @include rem(height, 10px);
}
